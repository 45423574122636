import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Box, Themed } from "theme-ui"
import parse, { domToReact } from "html-react-parser"

const PageWp = ({ data }) => {

  const page = data.allWpPage.nodes[0]

  const changeAbsolutePathsToRelativePaths = {
    replace: ({ name, attribs, children }) => {
      if (name === 'a' && attribs.href) {
        const link = attribs.href.replace('http://fundacionbibliotecasocial.local/','/');
        return <Link to={link}>{domToReact(children)}</Link>;
      }
    }
  }

  return (
    <Layout>
      <Themed.h1
        dangerouslySetInnerHTML={{ __html: page.title}}
      />
      <Box
        sx={{
          variant: 'styles',
        }}
      >
      {parse(page.content, changeAbsolutePathsToRelativePaths)}
      </Box>
    </Layout>
  )

}

export default PageWp

export const pageQuery = graphql`
  query($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
      }
    }
  }
`
